<template>
  <div class="operate">
      <template v-if="!isOnlyCopy">
        <el-button type="primary" plain @click="exportExcel"><i class="iconfont">&#xe601;</i> Excel</el-button>
        <el-button type="primary" plain @click="exportWordOrPdf('word')"><i class="iconfont">&#xe7ba;</i> Word</el-button>
        <el-button type="primary" plain @click="exportWordOrPdf('pdf')"><i class="iconfont">&#xe7b8;</i> Pdf</el-button>
        <el-button type="primary" plain icon="el-icon-document-copy" @click="copyExperiment"> 复制</el-button>
        <template v-if="!experimentObj.status||experimentObj.status!='4'">
          <el-button v-if="!(groupObj && groupObj.noEdit=='1' && experimentObj.status !='0')" type="primary" plain icon="el-icon-edit"   @click="$router.push('/manager/experiment/edit/'+experimentObj.group_id+'/'+experimentObj.form_template_id+'/'+experimentObj.uuid+'/0')"> 修改</el-button>
          <el-button type="primary" plain icon="el-icon-time" @click="listMark"> 痕迹</el-button>
          <el-button type="primary" plain icon="el-icon-share" @click="setShare"> 分享</el-button>
        </template>
      </template>
      <template v-else>
        <el-button  v-if="!hasRole([SYSTEM_ROLE.MANAGER])" type="primary" size="small" plain icon="el-icon-document-copy" @click="copyExperiment"> 复制</el-button>
      </template>


      <app-dialog ref="addDialog" dialogTitle="痕迹列表" @hide="closeMarkList" originTitle staticDialog width="500px">
          <div >
              <el-checkbox-group :max="2" class="checkboxGroup" style="display: flex;flex-direction: column;" @change="changeMark" v-model="checkMarkList">
                <template v-for="(mark,index) in markList">
                  <el-checkbox style="margin-top:10px;" :label="mark.uuid" :key="mark.uuid" :disabled="mark.version ==0" >
                    {{mark.create_time+":"+mark.user.name+" "+ mark.type+" 了该实验。"}} <span v-if="mark.version !=0" style="color:#409eff" @click="experiment(index)">{{'版本号：v'+mark.version}}</span>
                  </el-checkbox>
                </template>
              </el-checkbox-group>
          </div>
              <el-button v-if="markVersionList.length>1" :disabled="checkMarkList.length<=1" type="primary" style="width:100px;margin-top: 20px;" size="medium" @click="markCompare">痕迹比对</el-button>
      </app-dialog>
      <app-dialog ref="experimentDialog" dialogTitle="实验详情"  @hide="closeMarkDetail"  originTitle staticDialog width="90%">
         <el-button v-if="!hasRole([this.SYSTEM_ROLE.SHENJI])" type="primary" style="margin-bottom: 20px;" size="medium" @click="fallbackVersion(experimentObject)">回退到此版本</el-button>
        <div class="detail_metaInfo">
          <span class="title">实验编号</span>
          <span>{{experimentObject.experiment_id}}</span>
        </div>
         <div class="detail_metaInfo">
          <span class="title">实验名称</span>
          <span>{{experimentObject.subject}}</span>
        </div>
        
        <div class="detail_metaInfo" v-if="experimentObject.date">
          <span class="title">实验时间</span>
          <span>{{experimentObject.date}}</span>
        </div>
        <div class="detail_metaInfo" v-if="experimentObject.content">
          <span class="title">实验内容</span>
          <span>{{experimentObject.content}}</span>
        </div>
        <div class="detail_metaInfo" v-if="experimentObject.ins">
          <span class="title">实验地点</span>
          <span>{{experimentObject.ins}}</span>
        </div>

        <div class="detail_metaInfo" v-if="experimentObject.result">
          <span class="title">实验结果</span>
          <span>{{experimentObject.result}}</span>
        </div>
         <div class="detail_metaInfo"  v-if="experimentObj.keyword_tags&&experimentObj.keyword_tags.length>0">
              <span class="title">自定义标签</span>
              <el-tag :key="tag" v-for="tag in experimentObj.keyword_tags" type="success"   size="medium" style="margin-right:10px">{{tag}} </el-tag>
          </div>
        <div v-for="(value,key) in experimentObject.template_data" :key="key" class="detail_metaInfo">
          <span class="title">{{ruleFieldMap[key]}}</span>
          <span v-if="isHTML(value)"  v-html="value"></span>

          <span v-else-if="value.mol&&value.png">
            <img style='height:200px' v-if="value && value.png" :src="value.png"/>
          </span>
          <span v-else-if="value.indexOf('data:image/')!=-1">
            <img style='height:200px'  :src="value"/>
          </span>
          <span v-else v-html="value"></span>
        </div>
        <el-button type="primary" v-if="!hasRole([this.SYSTEM_ROLE.SHENJI])"  size="medium" @click="fallbackVersion(experimentObject)">回退到此版本</el-button>
      </app-dialog>

      <app-dialog ref="markDialog" class="markDialog" dialogTitle="痕迹比对"  @hide="closeMarkCompare" originTitle staticDialog width="90%">
          <el-row>
               <el-col :span="12" v-for="(item,index) in checkMarkList" :key="'trMark'+item" :style="index==0?'width:49.5%;margin-right:0.5%':'width:49.5%;margin-left:0.5%'">
                      <p v-if="markIdObj[item].version !=0"><b class="label">版本号：</b><b  style="color:#409eff">{{'v'+markIdObj[item].version}}</b></p>
                      <p ><b class="label">提交时间：</b>{{markIdObj[item].create_time}}</p>
                      <p :class="item+'_'+key" v-for="value,key in ruleFieldMap" :key="'mark'+key" >
                        <span>
                          <template v-if="FORM_EXPERIMENT_FIELD.indexOf(key)!=-1">
                              <b :class="'label title_'+key" v-if="markIdObj[item][key]">{{value+'：'}}</b>
                              
                              <template v-if="key=='annex'">
                                  <span > {{markIdObj[item][key] && markIdObj[item][key].length>0?markIdObj[item][key][0].name:''}}</span>
                              </template>
                              <template v-if="key=='keyword_tags'">
                                  <span >                        <el-tag :key="tag" v-for="tag in markIdObj[item][key]" type="success"   size="medium" style="margin-right:10px">{{tag}} </el-tag></span>
                              </template>
                              <template v-else>
                                  <span  v-html="markIdObj[item][key]"></span>
                              </template>
                          </template>
                          <template v-else>
                            <template>
                              <b :class="'label title_'+key" v-if="markIdObj[item]['template_data'][key]">{{value+'：'}}</b>
                              <template v-if="markIdObj[item]['template_data']">  
                                   <span v-if="isHTML(markIdObj[item]['template_data'][key])"  v-html="markIdObj[item]['template_data'][key]"></span>
                                  <template v-else-if="markIdObj[item]['template_data'][key] && markIdObj[item]['template_data'][key].mol&& markIdObj[item]['template_data'][key].png">
                                      <img width="200px" height="140px"  :src="markIdObj[item]['template_data'][key].png"/>
                                  </template>
                                  <template v-else-if="markIdObj[item]['template_data'][key] && markIdObj[item]['template_data'][key].toString().indexOf('data:image/')!=-1">
                                      <img width="200px" height="140px" :src="markIdObj[item]['template_data'][key]"/>
                                  </template>
                                  <template v-else>
                                      <span v-html="markIdObj[item]['template_data']?markIdObj[item]['template_data'][key]:''"></span>
                                  </template>
                                  </template>
                              </template>
                          </template>
                        </span>
                      </p>
            </el-col>
    
          </el-row>
      </app-dialog>
      <app-dialog ref="shareDialog" dialogTitle="分享实验" @hide="closeShare" originTitle staticDialog width="600px">
        <div style="margin-top:15px;">
          <span>请选择要分享的成员:</span>
          <el-checkbox-group v-model="shareIdList">
            <el-checkbox  style="margin-top:10px;" v-for="group in shareList" :label="group.userId?group.userId:group.id">{{group.userName?group.userName:group.name}}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="save_btn_warp">
          <el-button type="primary" style="width:50%;" size="medium" @click="shareExperiment">分享</el-button>
        </div>
      </app-dialog>
      <app-dialog ref="copyDialog" dialogTitle="复制实验" originTitle staticDialog width="500px">
           <el-select class="Width-100" v-model="projectId" filterable clearable placeholder="请选择项目">
                        <el-option
                            v-for="(item,key) in projectList"
                            :key="item.id"
                            :label="item.groupName"
                            :value="item.id">
                        </el-option>
                    </el-select>
          <div class="save_btn_warp">
              <el-button type="primary" style="width:50%;" size="medium" @click="goCopyExperiment">确定</el-button>
          </div>
      </app-dialog>
  </div>
  
</template>

<script>
import {ListMark, ExportExcel, ExportWordOrPdf, GetDetail, FallbackVersion} from "@/service/experiment";
import {
  GetGroupById,
  GetGroup,
  GetAllGroupUser,
  ShareGroupOrUser,
  GetMyProjectData,
  GetShareList, ShareExperiment
} from "@/service/user";
import {Detail} from "@/service/formTemplate";
export default {
  name: 'ActionPanel',
  components: {},
  mixins: [],
  props: {
    experimentObj: Object,
    editable: {
      type: Boolean,
      default: false
    },
    isOnlyCopy: {
      type: Boolean,
      default: false
    },
    isMark: {
      type: Boolean,
      default: true
    },
  },
  data () {
    return {
        id:"",
        groupObj:{},
        markList:[],
        markVersionList:[],
        checkMarkList:[],
        markIdObj:{},
        groupList:[],
        projectId:'',
        projectList:[],
        checkGroupList:[],
        groupIdUserMap:{},
        checkShareUserList:[],
        groupIdNameMap:{},
        groupId:"",
        ruleFieldMap:{},
        noteMap:{},
        noteList:[],
        shareList:[],
      shareIdList:[],
      experimentObject:{},
    }
  },
  computed: {
    dialogTitle () {
      return '插入表格'
    }
  },
  created(){
    this.id = this.experimentObj.uuid
      this.showCreate();
  },
  watch:{
    groupObj:function (newVal){
        if(newVal!=null && Object.keys(newVal).length==0){
            this.showCreate();
        }
    }
  },
  methods: {
    isHTML(str) {
    const tags = /<\/?("[^\"]*"|'[^\']*'|[^\>])*>/gi;
    return tags.test(str);
  },
    fallbackVersion(data){
      this.$confirm('确认要回退到此版本?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
      FallbackVersion(this.id,data).then(e => {
        this.checkMarkList=[];
        this.listMark();
        this.$refs['experimentDialog'].hide();
        if(e.code ==-1){
          this.$message.error(e.msg)
          return;
        }
        let data = this.APIParse(e)
        this.getDetail();
        this.$message.success("回退版本成功")
      })
      });
    },
    getDetail(){
      this.loading = true
      GetDetail(this.id).then(e => {
        this.loading = false
        let data = this.APIParse(e);
        this.experimentObj = data?.[0]?._source;
        if (this.isReview == '1' && '0'==this.experimentObj.status){
          this.getSignature()
        }
        if(this.experimentObj.template_data){
          this.experimentObj.template_data = JSON.parse(this.experimentObj.template_data)
        }
        Detail(this.experimentObj.form_template_id).then(formTemp => {
          let formData = this.APIParse(formTemp);
          if(formData.rule){
            let rule = JSON.parse(formData.rule);
            rule.forEach(ruleObj => {
              this.$set(this.ruleFieldMap,ruleObj.field,ruleObj.title)
            })
            this.ruleFieldMap['keyword_tags']='自定义标签'

          }
        })
      })
    },
    experiment(index){
     this.experimentObject=this.markList[index]
      this.$refs['experimentDialog'].show()
      Detail(this.experimentObject.form_template_id).then(formTemp => {
        let formData = this.APIParse(formTemp);
        if(formData.rule){
          let rule = JSON.parse(formData.rule);
          rule.forEach(ruleObj => {
            this.$set(this.ruleFieldMap,ruleObj.field,ruleObj.title)
          })
          this.ruleFieldMap['keyword_tags']='自定义标签'

        }
      })

    },
    showCreate(){
      if(this.experimentObj.form_template_id) {
        Detail(this.experimentObj.form_template_id).then(formTemp => {
          let formData = this.APIParse(formTemp);
          if (formData.rule) {
            let rule = JSON.parse(formData.rule);
            rule.forEach(ruleObj => {
              this.$set(this.ruleFieldMap, ruleObj.field, ruleObj.title)
            })
            this.ruleFieldMap['keyword_tags'] = '自定义标签'

          }
        })
        GetGroupById(this.experimentObj['group_id'][0]).then(e => {
          let data = this.APIParse(e);
          this.$set(this, "groupObj", data)
          // this.groupObj = data;
        })
      }
    },
    showListMark(obj){
      this.experimentObj=obj;
      this.id=obj.uuid;
      this.listMark();
    },
    listMark(){
        this.$refs['addDialog'].show()
        ListMark(this.id).then(e => {
            let data = this.APIParse(e);
            this.markList = data.map(item => {
                let source = item._source;
                if(source.version&&source.version!='0'){
                  this.markVersionList.push(source.version);
                }
                this.markIdObj[source.uuid] = source;
                if(source.template_data){
                    source.template_data = JSON.parse(source.template_data)
                }
                return source;
            });

        })
    },
    changeMark(){
        // this. clearMarkBack()
    },
    clearMarkBack(){
        this.$nextTick(() => {
            setTimeout(() => {
                for(let key in this.ruleFieldMap){
                    if(document.getElementsByClassName("title_"+key).length>0){
                        document.getElementsByClassName("title_"+key)[0].style.background = ''
                    }
                    for(let index in this.checkMarkList){
                        let markUuid = this.checkMarkList[index]
                        if(document.getElementsByClassName(markUuid+"_"+key).length>0){
                            document.getElementsByClassName(markUuid+"_"+key)[0].style.background = ''
                        }
                    }
                }
            })
        })
    },
    closeMarkList(){
        this.checkMarkList = [];
    },
    closeMarkDetail(){
        this.checkMarkList = [];
    },
    closeMarkCompare(){
        this.clearMarkBack();
        this.checkMarkList = [];

    },
    markCompare(){
        if(this.checkMarkList.length==0){
            this.$message.warning("还没有要选择比对的痕迹！")
            return
        }
        if(this.checkMarkList.length>0 && this.checkMarkList.length!=2){
            this.$message.warning("需要选择两个版本痕迹进行比对！")
            return
        }
        this.$refs['markDialog'].show()
        this.$nextTick(() => {
            setTimeout(() => {
                for(let key in this.ruleFieldMap){
                    let value = "";
                    let isSetBackgroud = false
                    for(let index in this.checkMarkList){
                        let curr_value = "";
                        let markUuid = this.checkMarkList[index]
                        let currObj = this.markIdObj[markUuid] 
                        if(this.FORM_EXPERIMENT_FIELD.indexOf(key)>=0){
                            if(key =='annex'){
                                if(currObj[key] && currObj[key].length>0){
                                    curr_value = currObj[key][0].name
                                }
                            }else{
                                curr_value = currObj[key];
                            }
                        }else{
                            if(currObj['template_data']){
                              if(currObj['template_data'][key].mol&&currObj['template_data'][key].png){
                                    curr_value = currObj['template_data'][key].png;
                                }else{
                                    curr_value = currObj['template_data'][key];
                                }
                            }
                        }
                        if(index != "0"){
                            if(value != curr_value){
                                isSetBackgroud = true;
                            }
                        }
                        value = curr_value;
                    }
                    if(isSetBackgroud){
                        document.getElementsByClassName("title_"+key)[0].style.background = '#eef3ff'
                         document.getElementsByClassName("title_"+key)[0].style.padding= '8px'
                        for(let index in this.checkMarkList){
                            let markUuid = this.checkMarkList[index]
                            document.getElementsByClassName(markUuid+"_"+key)[0].style.background = '#eef3ff'
                             document.getElementsByClassName(markUuid+"_"+key)[0].style.padding= '8px'

                        }
                    }
                }
            },100)
        })

    },
    setShare(){
      // if(!this.tipShareOrCopy('分享')){
      //   return
      // }
      GetShareList(this.experimentObj['group_id'][0]).then(e => {
        // let data = this.APIParse(e);
        if (e.data){
            if(e.data.length>0){
                this.$refs['shareDialog'].show()
                this.shareList=e.data
            }else{
                this.$message.warning("暂无可分享的用户")
            }
   
        }else{
          this.$message.warning(e.msg)
        }
      })
        // if("1" == this.groupObj.shareAddGroup || "1" == this.groupObj.shareOtherGroup){
        //     this.$refs['shareDialog'].show()
        //     let isAll = "";
        //     if("1" == this.groupObj.shareAddGroup){
        //         isAll = "0"
        //     }
        //     if("1" == this.groupObj.shareOtherGroup){
        //         isAll = "1"
        //     }
        //     console.log(isAll)
        //     GetGroup(isAll).then(e => {
        //         let data = this.APIParse(e);
        //         this.groupList = data
        //         data.forEach(item => {
        //             this.$set(this.groupIdNameMap,item.id,item.groupName)
        //         })
        //     })
        // }else{
        //   this.$message.warning("项目管理员未设置分享操作，暂时不支持分享！")
        // }
    },
    shareExperiment(){
      ShareExperiment(this.experimentObj.uuid,this.shareIdList.join(",")).then(e => {
        let data = this.APIParse(e);
        if(e.code ==-1){
          this.$message.error(e.msg)
          return;
        }
        this.$message.success("分享成功")
        this.closeShare()
      })
    },
    closeShare(){
        this.$refs['shareDialog'].hide()
        this.checkGroupList = []
        this.checkShareUserList = []
        this.groupIdUserMap = {}
      this.shareIdList=[];
        this.shareList=[];
    },
    changeGroup(){
        if("1"==this.groupObj.shareAppointPeople){
            this.groupIdUserMap = {}
            if(this.checkGroupList.length>0){
                GetAllGroupUser(this.checkGroupList.join(",")).then(e => {
                    let data = this.APIParse(e);
                    data.forEach(item => {
                        if(!this.groupIdUserMap[item.groupId]){
                            this.$set(this.groupIdUserMap,item.groupId,[item])
                        }else{
                            let groupArr = this.groupIdUserMap[item.groupId]
                            groupArr.push(item)
                            this.$set(this.groupIdUserMap,item.groupId,groupArr)
                        }
                    })
                })
            }
        }
    },
    copyExperiment(){
         GetMyProjectData().then(e => {
                    let data = this.APIParse(e);
                    this.projectList = data;
                    this.$refs['copyDialog'].show()
                })
    },
    goCopyExperiment(){
        if(!this.projectId){
            this.$message.warning("请选择复制实验的项目！")
            return
        }
        this.$router.push('/manager/experiment/edit/'+this.projectId+'/'+this.experimentObj.form_template_id+'/'+this.experimentObj.uuid+'/1')
    },
    exportExcel(){
        this.$message.info("Excel导出中")
        ExportExcel(this.experimentObj.uuid).then(e => {
            let data = this.APIParse(e);
            if(data.base64){
                this.$message.success("导出成功")
                this.downBlobToPage(data.base64,data.fileName)
            }
        }).catch(e => {
          this.$message.error("Excel导出失败")
        })
    },
    exportWordOrPdf(type){
        this.$message.info(type+"导出中")
        ExportWordOrPdf(this.experimentObj.uuid,type).then(e => {
            let data = this.APIParse(e);
            if(data.base64){
                this.$message.success("导出成功")
                this.downBlobToPage(data.base64,data.fileName)
            }
        }).catch(e => {
              this.$message.error(type+"导出失败")
        })
    },
    
  }
}
</script>
<style scoped lang="scss">
  .save_btn_warp{width: 100%;margin-top: 32px;display: flex;align-items: center;justify-content: center;}
  .detail_metaInfo+.detail_metaInfo {
    margin-top: 16px;
  }
  .detail_metaInfo{
    display: flex;
    line-height: 1.67;
    align-items: center;
  }
  .detail_metaInfo .title{
    font-weight: 600;
    margin-right: 30px;
    color: #8590a6;
    min-width: 80px;
  }
  .mySign {
    border: 1px dashed #000;
  }
  .detail_metaInfo span{
    word-break: break-word;
  }


</style>
<style>
.checkboxGroup .base-checkbox__input.is-disabled{
  display: none;
}
.checkboxGroup .base-checkbox__input.is-disabled .base-checkbox__inner{
  display: none !important;
}
.checkboxGroup .base-checkbox.is-disabled .base-checkbox__label{
  margin-left: 16px;
  cursor: text;
  color: #646464;
}
.markDialog .AppDialog__inner{
	max-height: 80vm !important;
	overflow-y: scroll;
}
.markDialog .AppDialog__inner .AppDialog__title--left{
  line-height: 40px;
  height: 40px;
}
.markDialog .AppDialog__inner .label{
  color: #999;
}
</style>