<template>
  <div class="vditor__editable">
    <div :id="'vditor'+suffix"  class="vditor"></div>
  </div>
</template>

<script>
import Vditor from 'vditor'
import 'vditor/dist/index.css';
import Input from './ui/input/Input.vue';

export default {
  components: { Input },
	name: "XVditor",
 data () {
    return {
      contentEditor :null,
      suffix:'',
      xVditorContent:'',
      				myTime:null

     }
  },
  props:{
			value:String
		},
    watch:{
			value:{
				handler:function (newVal){
					if(newVal){
						this.value=newVal

					}
				},
				immediate:true
			}
		},
    created(){
      this.suffix=parseInt(new Date().getTime() / 1000) + '';
    },
  mounted(){
     this.contentEditor = new Vditor('vditor'+this.suffix, {
      height: 360,
      placeholder:'请输入...',
      cache: {
        enable: false,
      },
      toolbarConfig: {
        pin: true,
      },
      toolbar: ['headings', 'bold' , 'italic', 'strike' ,'br',
      'line' , 'quote' , 'list' , 'ordered-list' , 'check' ,'outdent' ,
      'indent' , 'code' , 'inline-code' , 'insert-after' , 'insert-before' ,
      'undo' , 'redo' , 'upload' , 'link' , 'table' , 'edit-mode' , 
      'both' ,  'fullscreen' , 'outline' , 'help'],
      after: () => {
        if(this.value){
          this.contentEditor.setValue(this.value)
        }
        //this.xVditorContent=this.contentEditor.getValue()
      },
    })
  	this.myTime = setInterval(() => {
				this.getContent();
			},1000)
  },
  		unmounted(){
			if(this.myTime){
				clearInterval(this.myTime)
			}
		},
  methods: {
    getContent(){
        this.xVditorContent=this.contentEditor.getValue()
         this.$emit('input',this.xVditorContent)

    }
  }
}
</script>
<style >
.vditor{
  height: 400px !important;
}
.vditor .vditor-toolbar{padding-left: 10px !important;}
.vditor .vditor-reset{
  padding: 10px !important;
}
</style>